import router from '@/router'

export default {
  state: {
    allQuestions: {},
    detailedQuestion: [],
    candidatePublicCouncil: [],
    formationPublicCouncilsAll: [],
    detaileFormation: []
  },
  mutations: {
    setAllQuestions (state, payload) {
      state.allQuestions = payload
    },
    setDetailedQuestion (state, payload) {
      state.detailedQuestion = payload
    },
    setCandidatePublicCouncil (state, payload) {
      state.candidatePublicCouncil = payload
    },
    setFormationPublicCouncilsAll (state, payload) {
      state.formationPublicCouncilsAll = payload
    },
    setDetailedFormation (state, payload) {
      state.detaileFormation = payload
    }
  },
  actions: {
    async setFormationPublicCouncilsAll (ctx) {
      // формирование ос
      let res = await fetch(`${ctx.getters.getUrlApi}api/сommunity-сouncils`)
      res = await res.json()
      ctx.commit('setFormationPublicCouncilsAll', await res.data)
    },
    async setDetailedFormation (ctx, payload) {
      // формирование ос
      let res = await fetch(`${ctx.getters.getUrlApi}api/сommunity-сouncils/${payload}`)
      res = await res.json()
      ctx.commit('setDetailedFormation', await res.data)
    },
    async deleteFormationPC (ctx, payload) {
      // удалене формирование ос
      await fetch(`${ctx.getters.getUrlApi}api/admin/сommunity-сouncils/${payload}`, {
        method: 'DELETE',
        headers: {
          Authorization: localStorage.getItem('token')
        }
      })
        .then(res => {
          if (res.status === 200) {
            ctx.dispatch('setFormationPublicCouncilsAll')
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Общественный совет удален'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Произошла ошибка удаления'
            })
          }
        })
    },
    async updateFormationAdmin (ctx, payload) {
      // обн формирование ос
      await fetch(`${ctx.getters.getUrlApi}api/admin/сommunity-сouncils/${payload[1]}`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        },
        body: payload[0]
      })
        .then(res => {
          if (res.status === 200) {
            router.push('/admin/formation_councils')
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Редактирование общественного совета прошло успешно'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Произошла ошибка'
            })
          }
          return res.json()
        })
        .catch(error => console.log('error', error))
    },
    async createFormationAdmin (ctx, payload) {
      // создание формирование ос
      await fetch(`${ctx.getters.getUrlApi}api/admin/сommunity-сouncils`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        },
        body: payload
      })
        .then(res => {
          if (res.status === 200) {
            router.push('/admin/formation_councils')
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Создание общественного совета прошло успешно'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Произошла ошибка'
            })
          }
          return res.json()
        })
        .catch(error => console.log('error', error))
    },
    async setCandidatePublicCouncil (ctx) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/foiv`)
      res = await res.json()
      ctx.commit('setCandidatePublicCouncil', res.data)
    },
    async updateCandidatePCAdmin (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/foiv`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        },
        body: payload
      })
        .then(res => {
          if (res.status === 200) {
            router.push('/admin/candidate_public_council')
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Редактирование информации для кандидата прошло успешно'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Произошла ошибка'
            })
          }
          return res.json()
        })
        .catch(error => console.log('error', error))
    },
    async setAllQuestions (ctx) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/faq`)
      res = await res.json()
      ctx.commit('setAllQuestions', await res.data)
    },
    async setDetailedQuestion (ctx, payload) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/faq/${payload}`)
      res = await res.json()
      ctx.commit('setDetailedQuestion', await res.data)
    },
    async deleteQuestionAdmin (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/faq/${payload}`, {
        method: 'DELETE',
        headers: {
          Authorization: localStorage.getItem('token')
        }
      })
        .then(res => {
          if (res.status === 200) {
            ctx.dispatch('setAllQuestions')
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Вопрос удален'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Произошла ошибка удаления'
            })
          }
        })
    },
    async updateQuestionAdmin (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/faq/${payload[1]}`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        },
        body: payload[0]
      })
        .then(res => {
          if (res.status === 200) {
            router.push('/admin/asked_questions')
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Редактирование вопроса прошло успешно'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Произошла ошибка'
            })
          }
          return res.json()
        })
        .catch(error => console.log('error', error))
    },
    async createQuestionAdmin (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/faq`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        },
        body: payload
      })
        .then(res => {
          if (res.status === 200) {
            router.push('/admin/asked_questions')
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Создание вопроса прошло успешно'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Произошла ошибка'
            })
          }
          return res.json()
        })
        .catch(error => console.log('error', error))
    }
  },
  getters: {
    getAllQuestions (s) {
      return s.allQuestions
    },
    getDetailedQuestion (s) {
      return s.detailedQuestion
    },
    getCandidatePublicCouncil (s) {
      return s.candidatePublicCouncil
    },
    getFormationPublicCouncilsAll (s) {
      return s.formationPublicCouncilsAll
    },
    getDetailedFormation (s) {
      return s.detaileFormation
    }
  }
}
