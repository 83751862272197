export default {
  state: {
    newsForumList: {}, // список новостей для форума
    mainForumsList: [], // список главных форумов
    mainForumsItem: [], // выбранный главный форум
    contactsMainForum: [], // Контакты главного форума
    forumsList: [], // форумы в городах
    partnersForum: [], // Детальный партнеры форума
    partnersRegionalForum: [], // Детальный партнеры региона форума
    speakerRegionalForum: [], // Детальный спикер региона форума
    contactsItemForum: [], // Контакты форума-города
    currentForum: {},
    forumYear: [], // Список годов форума
    isMetrika: false
  },
  mutations: {
    SET_IS_METRIKA (state, payload) {
      state.isMetrika = payload
    },
    SET_NEWS_LIST (state, payload) {
      state.newsForumList = payload
    },
    SET_MAIN_FORUM_LIST (state, payload) {
      state.mainForumsList = payload
    },
    SET_ITEM_MAIN_FORUM (state, payload) {
      state.mainForumsItem = payload
    },
    SET_FORUM_LIST (state, payload) {
      state.forumsList = payload
    },
    SET_CURRENT_FORUM (state, payload) {
      state.currentForum = payload
    },
    SET_FORUM_YEAR (state, payload) {
      state.forumYear = payload
    },
    SET_PARTNERS (state, payload) {
      state.partnersForum = payload
    },
    SET_REGIONAL_PARTNERS (state, payload) {
      state.partnersRegionalForum = payload
    },
    SET_REGIONAL_SPEAKER (state, payload) {
      state.speakerRegionalForum = payload
    },
    SET_CONTACTS_MAIN (state, payload) {
      state.contactsMainForum = payload
    },
    SET_CONTACTS_ITEM (state, payload) {
      state.contactsItemForum = payload
    }
  },
  actions: {
    // Get Main forums list
    // ----- Request -----
    // Params:
    // year: год
    // Response: 200
    async getMainForumsList (ctx, payload) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/global-forums?year=${payload || ''}`)
      res = await res.json()
      this.commit('SET_MAIN_FORUM_LIST', await res.data)
    },
    // Get Main Item forum
    // ----- Request -----
    // Params:
    // id
    // if id === now - выводит форумы текущего года
    // Response: 200
    async getMainItemForum (ctx, payload) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/global-forums/${payload}`)
      res = await res.json()
      res.data && res.data.partners.sort((a, b) => a?.sort - b?.sort)
      this.commit('SET_ITEM_MAIN_FORUM', await res.data)
    },
    // Get forums list
    // ----- Request -----
    // Params:
    // id - регинонального форума
    // Response: 200
    async getCurrentForum (ctx, payload) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/admin/global-forums/regional-forum/${payload}`)
      res = await res.json()
      res.data && res.data.partners.sort((a, b) => a.sort - b.sort)
      this.commit('SET_CURRENT_FORUM', await res.data)
    },
    async getCurrentForumPublic (ctx, payload) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/regional-forums/${payload}`)
      res = await res.json()
      res.data && res.data.partners.sort((a, b) => a.sort - b.sort)
      this.commit('SET_CURRENT_FORUM', await res.data)
    },
    // Get contacts Main forum
    // Response: 200
    async getContactsMainForum (ctx) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/forum/contacts`)
      res = await res.json()
      this.commit('SET_CONTACTS_MAIN', await res.data)
    },
    //  Get contacts Item forum
    // Response: 200
    async getContactsItemForum (ctx, payloads) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/forum/contacts/${payloads}`)
      res = await res.json()
      this.commit('SET_CONTACTS_ITEM', await res.data)
    },
    // Get current forum
    async getForumsList (ctx) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/regional-forums`)
      res = await res.json()
      this.commit('SET_FORUM_LIST', await res.data)
    },
    // Get years forum
    async getYearForum (ctx) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/forum/years`)
      res = await res.json()
      this.commit('SET_FORUM_YEAR', await res.data)
    },
    // Partners
    // ----- Request -----
    // id
    async getPartnersForum (ctx, payload) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/admin/global-forums/partners/${payload}`)
      res = await res.json()
      this.commit('SET_PARTNERS', await res.data)
    },
    // Partners Region
    // ----- Request -----
    // id
    async getPartnersRegionalForum (ctx, payload) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/admin/global-forums/regional-forum/partners/${payload}`)
      res = await res.json()
      this.commit('SET_REGIONAL_PARTNERS', await res.data)
    },
    // Partners Region
    // ----- Request -----
    // id
    async getSpeakerRegionalForum (ctx, payload) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/admin/global-forums/regional-forum/speaker/${payload}`)
      res = await res.json()
      this.commit('SET_REGIONAL_SPEAKER', await res.data)
    },
    getNewsForumList ({ commit }, payload) {
      const content = {
        important: [
          { id: 1, dateTime: '20 августа, 2020', previewPhoto: 'forumnews.png', title: 'Форум Общественной палаты «Сообщество» впервые пройдет в онлайн-формате', description: '2–3 ноября в Гостином Дворе пройдет итоговый форум «Сообщество», организатором которого...', content: {}, rubric: 'форум', creator: {} },
          { id: 2, dateTime: '21 августа, 2020', previewPhoto: '', title: 'Национальный приоритет: демографическую повестку страны обсудят совместно с органами власти', description: '2–3 ноября в Москве пройдет итоговый форум «Сообщество», организованный Общественной ...', content: {}, rubric: 'форум', creator: {} },
          { id: 3, dateTime: '22 августа, 2020', previewPhoto: 'forumnews.png', title: 'Форум Общественной палаты «Сообщество» впервые пройдет в онлайн-формате', description: '2–3 ноября в Гостином Дворе пройдет итоговый форум «Сообщество», организатором которого...', content: {}, rubric: 'форум', creator: {} },
          { id: 4, dateTime: '23 августа, 2020', previewPhoto: '', title: 'Национальный приоритет: демографическую повестку страны обсудят совместно с органами власти', description: '2–3 ноября в Москве пройдет итоговый форум «Сообщество», организованный Общественной ...', content: {}, rubric: 'форум', creator: {} }
        ],
        content: {
          news: [
            {
              id: 1,
              dateTime: 'Сегодня, 13:35',
              previewPhoto: 'forumnews.png',
              title: 'Форум Общественной палаты «Сообщество» впервые пройдет в онлайн-формате',
              description: '2–3 ноября в Гостином Дворе пройдет итоговый форум «Сообщество», организатором которого...',
              content: {
                type: 'RIGHT_PIC',
                text: '<p>Текст статьи форума</p>',
                image: ['forumnews.png']
              },
              tagList: ['форум'],
              rubric: 'форум',
              creator: {}
            },
            {
              id: 2,
              dateTime: 'Сегодня, 13:35',
              previewPhoto: 'news3.png',
              title: 'Национальный приоритет: демографическую повестку страны обсудят совместно с органами власти',
              description: '2–3 ноября в Москве пройдет итоговый форум «Сообщество», организованный Общественной ...',
              content: {
                type: 'TEXT',
                text: '<p>Текст статьи форума</p>',
                image: []
              },
              tagList: ['форум'],
              rubric: 'форум',
              creator: {}
            }
          ]
        },
        total: 10,
        offset: 0, // Передаётся в запросе
        limit: 2, // Передаётся в запросе
        count: 2 // Фактическое число элементов в запросе
      }

      commit('SET_NEWS_LIST', content)
    }
  },
  getters: {
    GET_IS_METRIKA: s => s.isMetrika,
    GET_NEWS_LIST: s => s.newsForumList,
    GET_MAIN_FORUMS: (s) => s.mainForumsList, // список главных форумов
    GET_MAIN_ITEM_FORUM: (s) => s.mainForumsItem, // выбранный главный форум
    GET_FORUMS: (s) => s.forumsList, // форумы в городах
    GET_CURRENT_FORUM: (s) => s.currentForum,
    GET_REGIONAL_PARTNERS: (s) => s.partnersRegionalForum,
    GET_REGIONAL_SPEAKERS: (s) => s.speakerRegionalForum,
    GET_YEARS_FORUMS: (s) => s.forumYear, // Список годов форума
    GET_PARTNERS: (s) => s.partnersForum, // Список партнёров форума
    GET_CONTACTS_MAIN: (s) => s.contactsMainForum, // Список контактов главного форума
    GET_CONTACTS_ITEM: (s) => s.contactsItemForum // Список контактов детального форума
  }
}
